<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current" viewBox="0 0 15.186 8.021">
    <path id="Trazado_97" data-name="Trazado 97" d="M-684.17,802.2l5.179,5.179a1.456,1.456,0,0,0,2.06,0l5.118-5.118" transform="translate(685.584 -800.781)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'icon-arrow-down'
}
</script>

<style scoped>

</style>
