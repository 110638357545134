<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="fill-current stroke-current text-white" viewBox="0 0 14.89 125.86">
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path class="cls-1"
              d="M2.64,38.6a.7.7,0,0,1,.22.19.37.37,0,0,1,.08.26.74.74,0,0,1-.17.4A5.71,5.71,0,0,0,2.4,40a4,4,0,0,0-.38.83A3.61,3.61,0,0,0,1.85,42,3.66,3.66,0,0,0,2,43.17,2.64,2.64,0,0,0,2.5,44a2,2,0,0,0,.71.5,2.29,2.29,0,0,0,.87.17,1.69,1.69,0,0,0,1-.29,2.62,2.62,0,0,0,.67-.79,5.2,5.2,0,0,0,.48-1.09l.43-1.26c.15-.44.31-.85.49-1.26a4.58,4.58,0,0,1,.7-1.1,3.12,3.12,0,0,1,1-.79,3.55,3.55,0,0,1,1.5-.29,4.83,4.83,0,0,1,1.76.32,4.2,4.2,0,0,1,1.44.93,4.38,4.38,0,0,1,1,1.52,5.57,5.57,0,0,1,.35,2,6.2,6.2,0,0,1-.51,2.54,5.9,5.9,0,0,1-1.36,2l-.92-.56a.65.65,0,0,1-.18-.19.42.42,0,0,1-.08-.26.85.85,0,0,1,.22-.48,7.78,7.78,0,0,0,.5-.67,5.94,5.94,0,0,0,.5-1,3.94,3.94,0,0,0,.22-1.41,3.61,3.61,0,0,0-.19-1.23,2.63,2.63,0,0,0-.53-.91,2.31,2.31,0,0,0-.83-.58,2.83,2.83,0,0,0-1.07-.2,1.75,1.75,0,0,0-1.06.3,2.37,2.37,0,0,0-.7.77,5.87,5.87,0,0,0-.48,1.1c-.13.41-.26.83-.4,1.26a10.37,10.37,0,0,1-.47,1.25,3.89,3.89,0,0,1-.7,1.1,3.23,3.23,0,0,1-1.08.78,3.89,3.89,0,0,1-1.59.29,3.89,3.89,0,0,1-1.47-.29,4,4,0,0,1-1.26-.86A4,4,0,0,1,.57,44a5.11,5.11,0,0,1-.33-1.9,6.14,6.14,0,0,1,.38-2.19,5,5,0,0,1,1.1-1.73Z"/>
        <path class="cls-1"
              d="M6.4,28.71a.88.88,0,0,1,.17.16.37.37,0,0,1,.06.23.56.56,0,0,1-.13.33,4.5,4.5,0,0,0-.27.44,3.64,3.64,0,0,0-.27.65,3,3,0,0,0-.13.95,3,3,0,0,0,.27,1.31,2.43,2.43,0,0,0,.76.95,3.24,3.24,0,0,0,1.21.58,5.58,5.58,0,0,0,1.59.2,5.76,5.76,0,0,0,1.63-.21,3.69,3.69,0,0,0,1.21-.59,2.51,2.51,0,0,0,.73-.92,2.91,2.91,0,0,0,.26-1.23,3,3,0,0,0-.16-1.07,3,3,0,0,0-.34-.7,4.18,4.18,0,0,0-.35-.46.56.56,0,0,1-.15-.36.38.38,0,0,1,.17-.34l.65-.5a3.86,3.86,0,0,1,1.19,1.65,5.86,5.86,0,0,1,.37,2.09,4.54,4.54,0,0,1-.35,1.77,3.93,3.93,0,0,1-1,1.41,4.74,4.74,0,0,1-1.63,1,6.58,6.58,0,0,1-2.21.34A6.68,6.68,0,0,1,7.57,36a4.58,4.58,0,0,1-1.65-.92,4.09,4.09,0,0,1-1.09-1.49,5,5,0,0,1-.39-2,4.81,4.81,0,0,1,.35-1.88,4.45,4.45,0,0,1,1-1.45Z"/>
        <path class="cls-1"
              d="M14.73,26.28H4.6v-1a.58.58,0,0,1,.11-.4.71.71,0,0,1,.38-.15l1.58-.12A4.55,4.55,0,0,1,5,23.31a2.83,2.83,0,0,1-.6-1.8,2.59,2.59,0,0,1,.1-.76,2.53,2.53,0,0,1,.26-.63l1.33.23a.3.3,0,0,1,.25.31,1.63,1.63,0,0,1-.09.43,2.46,2.46,0,0,0-.1.81,2.31,2.31,0,0,0,.54,1.56,4.1,4.1,0,0,0,1.57,1h6.45Z"/>
        <path class="cls-1"
              d="M4.44,14.12a5.09,5.09,0,0,1,.37-2A4.22,4.22,0,0,1,5.86,10.6a4.53,4.53,0,0,1,1.64-1A6.44,6.44,0,0,1,9.66,9.3a6.36,6.36,0,0,1,2.16.34,4.53,4.53,0,0,1,1.64,1,4.08,4.08,0,0,1,1,1.52,5.09,5.09,0,0,1,.37,2,5.18,5.18,0,0,1-.37,2,4.25,4.25,0,0,1-1,1.52,4.7,4.7,0,0,1-1.64,1A6.36,6.36,0,0,1,9.66,19a6.44,6.44,0,0,1-2.16-.34,4.7,4.7,0,0,1-1.64-1,4.4,4.4,0,0,1-1.05-1.52A5.18,5.18,0,0,1,4.44,14.12Zm9,0a2.62,2.62,0,0,0-1-2.24,4.69,4.69,0,0,0-2.81-.74,4.71,4.71,0,0,0-2.82.74,2.6,2.6,0,0,0-1,2.24,3.14,3.14,0,0,0,.26,1.32,2.45,2.45,0,0,0,.75.94,3.47,3.47,0,0,0,1.21.56,6.55,6.55,0,0,0,1.61.18,6.55,6.55,0,0,0,1.61-.18,3.51,3.51,0,0,0,1.2-.56,2.52,2.52,0,0,0,.74-.94A3.14,3.14,0,0,0,13.48,14.12Z"/>
        <path class="cls-1" d="M0,5.12H14.73V6.9H0Z"/>
        <path class="cls-1" d="M0,0H14.73V1.78H0Z"/>
        <line class="cls-2" x1="8.91" y1="125.86" x2="8.91" y2="58.36"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'icon-scroll'
}
</script>

<style scoped>

</style>
