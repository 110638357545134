<template>
  <svg width="29" class="fill-current" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M26.51 29H2.51H2.37C1.70148 28.8745 1.09716 28.5211 0.66 28C0.326477 27.5618 0.100419 27.0515 0 26.51L0 2.51002C0.0220078 2.47602 0.0388574 2.43895 0.05 2.40002C0.172057 1.71264 0.537165 1.09204 1.07872 0.651459C1.62027 0.210876 2.30216 -0.0203323 3 1.66484e-05H26C26.2626 -0.000296215 26.5244 0.0299063 26.78 0.0900167C27.4263 0.249375 27.9988 0.624779 28.4025 1.15402C28.8063 1.68326 29.0171 2.33457 29 3.00002V26.24C28.9734 26.6229 28.8714 26.9967 28.6998 27.3399C28.5281 27.6831 28.2903 27.9891 28 28.24C27.5718 28.6097 27.0607 28.8705 26.51 29ZM14.79 11.44C13.2641 11.3416 11.7737 10.9366 10.4078 10.2494C9.04185 9.56213 7.82844 8.60665 6.84 7.44002C6.444 8.09698 6.28847 8.87109 6.4 9.63002C6.42943 10.1973 6.57614 10.7524 6.83088 11.2601C7.08561 11.7678 7.44283 12.2173 7.88 12.58V12.66L6.45 12.28C6.44476 13.1416 6.72717 13.9802 7.25247 14.6631C7.77778 15.346 8.51594 15.8341 9.35 16.05V16.12H7.85C8.0963 16.8814 8.56529 17.5514 9.19636 18.0434C9.82743 18.5354 10.5916 18.8268 11.39 18.88C9.81666 20.0954 7.8386 20.6641 5.86 20.47C7.43072 21.4977 9.24556 22.0911 11.12 22.19C13.1601 22.2804 15.1881 21.8321 17 20.89C19.3605 19.6408 21.1506 17.532 22 15C22.4688 13.6435 22.7317 12.2244 22.78 10.79C22.8004 10.6569 22.8636 10.534 22.96 10.44C23.29 10.11 23.65 9.82002 23.96 9.44002C24.1329 9.2099 24.2804 8.96179 24.4 8.70002L22.49 9.22002V9.15002C23.2028 8.63594 23.7264 7.90145 23.98 7.06002L23.58 7.24002L21.75 7.88002C21.6839 7.90524 21.6111 7.9071 21.5438 7.88527C21.4765 7.86344 21.4187 7.81924 21.38 7.76002C20.9845 7.31567 20.4659 6.99892 19.89 6.85002C18.9244 6.58116 17.893 6.68466 17 7.14002C16.4508 7.35927 15.9615 7.70561 15.5721 8.15061C15.1827 8.59562 14.9044 9.12661 14.76 9.70002C14.63 10.274 14.6403 10.8709 14.79 11.44Z"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="29" height="29" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'icont-twitter'
}
</script>

<style scoped>

</style>
