<template>
  <svg width="134" height="135" viewBox="0 0 134 135" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path
        d="M67 127C101.794 127 130 98.5701 130 63.5C130 28.4299 101.794 0 67 0C32.2061 0 4 28.4299 4 63.5C4 98.5701 32.2061 127 67 127Z"
        fill="black"/>
      <path d="M54.05 84.47V63.98V43.48L72.0501 53.73L90.0501 63.98L72.0501 74.22L54.05 84.47Z" fill="white"/>
    </g>
    <defs>
      <filter id="filter0_d" x="0" y="0" width="134" height="135" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>

</template>

<script>
export default {
  name: 'icon-button-play'
}
</script>

<style scoped>

</style>
