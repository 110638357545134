<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-white" viewBox="0 0 37 28.3">
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <rect class="cls-1" y="26" width="37" height="2.3"/>
        <rect class="cls-1" y="13" width="37" height="2.3"/>
        <rect class="cls-1" width="37" height="2.3"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'icon-menu'
}
</script>

<style scoped>

</style>
