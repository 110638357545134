<template>
  <svg width="29" height="29" viewBox="0 0 29 29" class="fill-current" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M25.15 29H3.85H3.69C2.84521 28.873 2.05705 28.4983 1.42517 27.9234C0.793287 27.3484 0.34605 26.5991 0.14 25.77C0.08 25.59 0.05 25.39 0 25.21V3.79C0.0211339 3.7416 0.0378681 3.6914 0.05 3.64C0.187719 2.80694 0.564368 2.0318 1.1342 1.40871C1.70403 0.785625 2.44253 0.341403 3.26 0.13L3.79 0H25.2L25.33 0.05C26.3563 0.210342 27.291 0.733442 27.9644 1.52429C28.6378 2.31515 29.0053 3.32129 29 4.36V24.63C29.0089 25.5539 28.7228 26.4565 28.1832 27.2065C27.6437 27.9565 26.8788 28.5148 26 28.8C25.7 28.88 25.42 28.93 25.15 29ZM25.54 14.53V7.85C25.561 7.26785 25.4618 6.68762 25.2486 6.14551C25.0354 5.60339 24.7128 5.11102 24.3009 4.69911C23.889 4.28721 23.3966 3.96459 22.8545 3.7514C22.3124 3.53821 21.7321 3.43901 21.15 3.46H7.85C7.26872 3.43909 6.68936 3.53801 6.14794 3.75059C5.60653 3.96318 5.11465 4.28489 4.70288 4.69571C4.29112 5.10653 3.96829 5.59768 3.75447 6.13861C3.54065 6.67954 3.44042 7.25868 3.46 7.84C3.46 12.28 3.46 16.7167 3.46 21.15C3.43909 21.7313 3.53801 22.3106 3.75059 22.8521C3.96318 23.3935 4.28489 23.8854 4.69571 24.2971C5.10653 24.7089 5.59768 25.0317 6.13861 25.2455C6.67954 25.4593 7.25868 25.5596 7.84 25.54C12.28 25.54 16.7167 25.54 21.15 25.54C21.7321 25.561 22.3124 25.4618 22.8545 25.2486C23.3966 25.0354 23.889 24.7128 24.3009 24.3009C24.7128 23.889 25.0354 23.3966 25.2486 22.8545C25.4618 22.3124 25.561 21.7321 25.54 21.15V14.53Z"/>
      <path d="M5.15001 14.49V7.86004C5.12986 7.49973 5.18581 7.13921 5.31424 6.80196C5.44266 6.46471 5.64067 6.15829 5.89538 5.90264C6.15008 5.64699 6.45577 5.44785 6.79254 5.31818C7.12932 5.18851 7.48962 5.13122 7.85001 5.15004H21.15C21.5077 5.12987 21.8656 5.1853 22.2004 5.31272C22.5353 5.44013 22.8395 5.63667 23.0933 5.88952C23.3471 6.14237 23.5448 6.44586 23.6734 6.78022C23.8021 7.11457 23.8588 7.47228 23.84 7.83004C23.84 12.2767 23.84 16.7234 23.84 21.17C23.8573 21.5274 23.7994 21.8844 23.6702 22.2181C23.541 22.5517 23.3433 22.8545 23.0898 23.1071C22.8363 23.3596 22.5328 23.5562 22.1986 23.6841C21.8645 23.8121 21.5073 23.8686 21.15 23.85H7.85001C7.48962 23.8689 7.12932 23.8116 6.79254 23.6819C6.45577 23.5522 6.15008 23.3531 5.89538 23.0974C5.64067 22.8418 5.44266 22.5354 5.31424 22.1981C5.18581 21.8609 5.12986 21.5003 5.15001 21.14V14.49ZM14.51 8.55004C13.3187 8.53817 12.1509 8.88121 11.1552 9.53545C10.1596 10.1897 9.38127 11.1255 8.91942 12.2237C8.45757 13.3219 8.3331 14.5327 8.56187 15.7019C8.79064 16.8711 9.3623 17.9457 10.204 18.7888C11.0457 19.6319 12.1194 20.2054 13.2881 20.4361C14.4569 20.6669 15.668 20.5444 16.7669 20.0844C17.8659 19.6244 18.803 18.8477 19.4589 17.8532C20.1149 16.8586 20.4599 15.6913 20.45 14.5C20.437 12.9278 19.8073 11.4234 18.6964 10.3107C17.5855 9.19793 16.0823 8.56571 14.51 8.55004ZM20.51 10.25C20.7319 10.2487 20.9514 10.2037 21.156 10.1176C21.3605 10.0314 21.5461 9.90585 21.7021 9.74799C21.8581 9.59013 21.9815 9.40308 22.0652 9.19754C22.1489 8.992 22.1913 8.77197 22.19 8.55004C22.19 8.21296 22.0898 7.8835 21.9021 7.60352C21.7144 7.32354 21.4477 7.10568 21.1359 6.97762C20.8241 6.84956 20.4812 6.81708 20.1509 6.88432C19.8206 6.95155 19.5178 7.11545 19.2808 7.35521C19.0439 7.59496 18.8836 7.89974 18.8203 8.23082C18.7569 8.56189 18.7935 8.90431 18.9252 9.21458C19.057 9.52484 19.278 9.78894 19.5602 9.97331C19.8423 10.1577 20.173 10.254 20.51 10.25Z"/>
      <path d="M14.49 18.75C13.6494 18.748 12.8283 18.4968 12.1305 18.0282C11.4327 17.5595 10.8895 16.8945 10.5697 16.1172C10.2499 15.3398 10.1677 14.4851 10.3336 13.6611C10.4996 12.837 10.9061 12.0807 11.5019 11.4877C12.0976 10.8948 12.8559 10.4918 13.6807 10.3297C14.5055 10.1677 15.3598 10.2539 16.1356 10.5773C16.9115 10.9008 17.5739 11.4471 18.0393 12.1471C18.5046 12.8471 18.752 13.6694 18.75 14.51C18.7421 15.6355 18.2901 16.7124 17.4923 17.5064C16.6946 18.3005 15.6156 18.7474 14.49 18.75Z"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="29" height="29" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'icon-instagram'
}
</script>

<style scoped>

</style>
