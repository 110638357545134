<template>
  <svg class="stroke-current" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.05322 0.975586L10.0013 10.5245L0.946898 20.4753" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'icon-arrow-right'
}
</script>

<style scoped>

</style>
