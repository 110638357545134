<template>
  <svg class="stroke-current" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.18005 0.650146L0.680054 6.15015L5.68005 12.1501" stroke-miterlimit="10"/>
  </svg>
</template>

<script>
export default {
  name: 'icon-arrow-left'
}
</script>

<style scoped>

</style>
