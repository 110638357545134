<template>
  <div class="h-footer bg-black flex items-center justify-center">
    <p class="text-white text-sm">© 2021 Filming Theory</p>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>
