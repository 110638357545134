<template>
  <svg width="29" height="29" viewBox="0 0 29 29" class="fill-current" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M14.5 29H3.06C2.58246 29.0181 2.10774 28.9191 1.67722 28.7117C1.2467 28.5043 0.873446 28.1947 0.589996 27.81C0.185112 27.2649 -0.022889 26.5986 -3.50713e-06 25.92V3C-3.50713e-06 2.20435 0.316067 1.44129 0.878676 0.87868C1.44129 0.31607 2.20435 0 3 0L26 0C26.7956 0 27.5587 0.31607 28.1213 0.87868C28.6839 1.44129 29 2.20435 29 3V26C29 26.7956 28.6839 27.5587 28.1213 28.1213C27.5587 28.6839 26.7956 29 26 29H14.5ZM15.94 26.09H20.32V16H24.65V11.59H20.31C20.31 10.59 20.31 9.59 20.31 8.66C20.3126 8.4533 20.3568 8.24925 20.44 8.06C20.5356 7.81758 20.7046 7.61111 20.9234 7.46946C21.1421 7.32781 21.3997 7.25807 21.66 7.27C22.57 7.27 23.48 7.27 24.39 7.27C24.59 7.27 24.66 7.27 24.66 7.01C24.66 5.72 24.66 4.42 24.66 3.13C24.66 3.06 24.66 2.98 24.66 2.9H24.5C23.29 2.9 22.08 2.9 20.87 2.9C20.207 2.91476 19.554 3.06421 18.9506 3.33927C18.3472 3.61433 17.806 4.00924 17.36 4.5C16.8935 4.97797 16.5276 5.54456 16.2837 6.1663C16.0398 6.78805 15.9229 7.45235 15.94 8.12V11.32C15.94 11.41 15.94 11.49 15.94 11.6H13.05C13.0465 11.6466 13.0465 11.6934 13.05 11.74C13.05 13.08 13.05 14.43 13.05 15.74C13.05 15.91 13.12 15.93 13.27 15.93H15.96L15.94 26.09Z"
        />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="29" height="29" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'icon-facebook'
}
</script>

<style scoped>

</style>
